/* You can add global styles to this file, and also import other style files */

/*html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
@import "~@angular/material/prebuilt-themes/pink-bluegrey.css";

*/


@import '~@angular/material/prebuilt-themes/deeppurple-amber.css';
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
